// *************************************
//
// Button
//
// *************************************

select {
  -moz-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
  border: 1px solid fade-out($color-contrast, 0.78);
  border-radius: 2px;
  color: $color-contrast;
  padding: 4px 10px;
  cursor: pointer;
  appearance: none;
  padding-right: 30px;
  background: url("/images/icon-select-arrow.svg") right center no-repeat;
  background-color: $color-off-white;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: darken($color-off-white, 5%);
    color: darken($color-contrast, 5%);
  }

  &:focus {
    border-color: fade-out($color-contrast, 0.3);
  }

  &:disabled {
    pointer-events: none;
    cursor: default;
    color: fade-out($color-contrast, 0.6);
    background: fade-out($color-contrast, 0.85);
  }
}

// -------------------------------------
// Modifiers
// -------------------------------------

// -------------------------------------
// States
// -------------------------------------

// -------------------------------------
// Context
// -------------------------------------

// -------------------------------------
// Flex
// -------------------------------------
