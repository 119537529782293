// *************************************
//
// Style
// Arcanae accounts
//
// *************************************

// -------------------------------------
// Fonts
// -------------------------------------

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  src: local("PT Sans Regular"), local("PTSans-Regular"),
    url("../fonts/PTSans-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 400;
  src: local("PT Sans Italic"), local("PTSans-Italic"),
    url("../fonts/PTSans-Italic.ttf") format("ttf");
}

@font-face {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 700;
  src: local("PT Sans Bold"), local("PTSans-Bold"),
    url("../fonts/PTSans-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "PT Sans";
  font-style: italic;
  font-weight: 700;
  src: local("PT Sans Bold Italic"), local("PTSans-BoldItalic"),
    url("../fonts/PTSans-BoldItalic.ttf") format("ttf");
}

@font-face {
  font-family: "Gentium Plus";
  src: local("Gentium Plus"), local("GentiumPlus"),
    url("../fonts/GentiumPlus-R.woff") format("woff");
}

@font-face {
  font-family: "Gentium Plus";
  font-style: italic;
  src: local("Gentium Plus Italic"), local("GentiumPlus-Italic"),
    url("../fonts/GentiumPlus-I.woff") format("woff");
}

// -------------------------------------
// Core
// -------------------------------------
@import "core/normalize";
@import "core/variables";
@import "core/helpers";
@import "core/utilities";
@import "core/base";

// -------------------------------------
// Vendor
// -------------------------------------

// -------------------------------------
// Modules
// -------------------------------------
@import "modules/grid";
@import "modules/button";
@import "modules/form";
@import "modules/select";

// -------------------------------------
// Components
// -------------------------------------
@import "components/header";
@import "components/connexion-page";
@import "components/modal";
@import "components/nav-bar";
@import "components/text-field";
@import "components/users";
@import "components/sign-up-form";
@import "components/welcome";
@import "components/switch-button";
@import "components/visible-password";

// -------------------------------------
// Inbox
// -------------------------------------

html,
body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

html,
body,
#root {
  height: 100%;
}

body {
  overflow: hidden;
}

.page {
  padding-left: 10%;
  padding-right: 10%;
  height: calc(100% - #{$page-header-height});
}

.my-account-page {
  padding: 40px 20px;
  margin: auto;
  max-width: 1000px;
  height: calc(100% - #{$page-header-height});
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
}

.my-account-page-infos {
  max-width: 260px;
  padding-right: 36px;
  border-right: 1px solid #e0e0e0;
}

.my-account-page-apps {
  flex: 1 1 auto;
  padding-left: 40px;
}

.my-account-page-apps-title {
  font-size: 14px;
  text-transform: uppercase;
  color: $pallet-noir;
  margin-bottom: 24px;
  font-weight: 700;
}

.my-account-page-apps-content {
  display: flex;
  flex-wrap: wrap;
}

.my-account-page-app {
  flex: 1 1 auto;
  text-align: center;
  padding: 18px;
  background-color: $color-pure-white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  margin: 0 30px 30px 0;
  max-width: 200px;

  &:last-child {
    margin-right: 0;
  }

  p {
    color: $pallet-vert-de-gris;
    font-size: 14px;
    font-family: $sans-family;
    font-weight: 700;
  }
}

.my-account-page-app-logo {
  height: 40px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;

  .my-account-page-app--vega & {
    background-image: url("../images/logo-vega-gold.svg");
  }
}

.my-account-page-app-button {
  display: block;
  padding: 12px 18px;
  color: $color-pure-white;
  margin-top: 24px;
  border-radius: 3px;

  span {
    display: block;
  }

  .my-account-page-app--vega & {
    background-color: $pallet-vert-de-gris;
  }

  .my-account-page-app-button-small-text {
    font-size: 12px;
  }

  &:hover {
    background-color: fade-out($pallet-vert-de-gris, 0.2);
  }
}

.connexion-page {
  height: calc(100% - #{$page-header-height});
  overflow: auto;

  .connexion-page-layout {
    display: flex;
    max-width: 640px;
    width: 100%;
    padding: 0 20px;
    margin: auto;
    margin-top: 40px;
  }
}

.title {
  color: $color-contrast;
  font-size: 26px;
  font-weight: 700;
  font-family: $sans-family;
  text-transform: uppercase;
}

.sub-title {
  color: $color-contrast;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: $ff-serif;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.description {
  color: $color-contrast;
  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
  font-family: $ff-serif;
  margin-bottom: 40px;
}

.link {
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-family: $ff-copy;
  color: $color-contrast;
  &:hover {
    color: darken($color-contrast, 20%);
  }
}

.link--clear {
  padding: 0;
  border: 0;
  background-color: transparent;
  text-decoration: none;
  color: $color-pure-white;
  &:hover {
    color: darken($color-pure-white, 20%);
  }
}

.link--small {
  font-size: 14px;
  text-decoration: none;
  color: $color-copy;
  &:hover {
    color: darken($color-copy, 20%);
  }
}

// -------------------------------------
// Tags
// -------------------------------------

.tagContainer {
  padding: 25px 0px 0px 0px;
}

.tag {
  font-size: 14px;
  font-weight: 700;
  color: $color-pure-white !important;
  background-color: $pallet-gris-2;
  border-radius: 10px;
  padding: 4px 7px;
  gap: 10px;
  display: inline;
}
