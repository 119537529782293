.sign-up-form {
  display: flex;
  flex-flow: wrap;
  .first-name {
    flex: 1 0 48%;
    margin-right: 4%;
  }
  .last-name {
    flex: 1 0 48%;
  }
  .email {
    flex: 2 0 100%;
  }
  .password {
    flex: 1 0 48%;
    margin-right: 4%;
  }
  .password-confirm {
    flex: 1 0 48%;
  }

  .check-field {
    cursor: pointer;
    // flex: 0 1;
    display: block;
    margin: 20px 0px 20px auto;
    white-space: nowrap;

    > input {
      width: 13px;
      height: 13px;
      padding: 0;
      margin: 0 0 0 10px;
      vertical-align: bottom;
      position: relative;
      top: -2px;
      overflow: hidden;
    }
  }
}
