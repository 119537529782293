// *************************************
//
// Base
// Base-level tags (body, p, etc.)
//
// *************************************

body {
  font-family: $sans-family;
  color: $color-copy;
  background-color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// ----- Form ----- //

fieldset {
  border: 0;
  padding: 0.01em 0 0;
  margin: 0;
  min-width: 0;
}

legend {
  padding: 0;
  display: table;
  margin: 0 0 $base-whitespace;
  font-size: 14px;
  line-height: inherit;
}

label,
.label {
  font-size: 14px;
  color: tint($color-copy, 40%) !important;
  padding: 0 $base-whitespace-s $base-whitespace-xs 0;
  display: inline-block;
}

input,
select,
textarea,
button {
  margin: 0;
  line-height: inherit;
}

input {
  outline: none;
  width: 100%;
  color: $color-contrast;
  font-family: $ff-serif;
  font-size: 18px;
  font-weight: 400;
}

input[type="search"] {
  box-sizing: inherit;
  -webkit-appearance: none;
}

// https://blog.mariusschulz.com/2016/03/20/how-to-remove-webkits-banana-yellow-autofill-background
input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0px 9999px white;
}

select {
  background-size: 26px 26px;
}

ul {
  padding: 0;
  margin-top: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
a:hover,
a:active,
a:focus {
  outline: none;
}
