// *************************************
//
// Form
//
// *************************************
.form-item {
  margin-bottom: 30px;
}

// -------------------------------------
// Submodules
// -------------------------------------
.form-link {
  color: #8a8a8a;
  font-family: $ff-copy;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  
  &:hover {
    color: darken(#8a8a8a, 20%);
  }
}

.form-error {
  margin-bottom: 0px;
  color: $color-alert;
}

.submit-success {
  font-family: $ff-serif;
  font-size: 22px;
  margin-bottom: 100px;
  color: $color-contrast;
  font-weight: 400;
}

.forgotten-password-form {
  .form-error {
    margin-bottom: 30px;
  }
}
