// *************************************
//
// Switch button.
//
// *************************************
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 40px;
  height: 20px;
  background: #c6c6c5;
  border-radius: 20px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16.33px;
  border-radius: 45px;
  transition: 0.2s;
  background: $color-pure-white;
  box-shadow: 1px 1px 2px rgba(0, 14, 51, 0.1);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  // width: 35px;
}

.react-switch-checkbox:checked + .react-switch-label {
  background: $pallet-vert-de-gris;
}
