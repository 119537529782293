// *************************************
//
// Button
//
// *************************************

.button {
  -moz-user-select: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $sans-family;
  font-size: 9px;
  text-transform: uppercase;
  color: $color-contrast;
  min-width: 22px;
  padding: 4px 8px;
  margin-right: 2px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background-color: #dfe3e6;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;

  &:hover {
    background-color: fade-out($color-contrast, 0.7);
  }
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: fade-out($color-contrast, 0.85);
    color: fade-out($color-contrast, 0.4);
  }
}

// -------------------------------------
// Modifiers
// -------------------------------------
.button--medium {
  font-size: 14px;
  font-weight: 400;
  padding: 12px 40px;
  border-radius: 3px;
  color: white;
  background-color: $color-contrast;
  text-transform: uppercase;
  font-family: $sans-family;

  &:hover {
    background-color: fade-out($color-contrast, 0.2);
  }
  &:disabled {
    background-color: $pallet-sable;
    color: $pallet-gris-2;
  }
}

.button--large {
  font-size: 18px;
  font-weight: 400;
  padding: 12px 40px;
  border-radius: 3px;
  color: white;
  background-color: $color-contrast;

  &:hover {
    background-color: fade-out($color-contrast, 0.2);
  }
  &:disabled {
  }
}

.link-button {
  font-family: $sans-family;
  display: block;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  margin: 4px 40px;

  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  color: $color-contrast;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    text-decoration: underline;
  }
}
// -------------------------------------
// States
// -------------------------------------

// -------------------------------------
// Context
// -------------------------------------

// -------------------------------------
// Flex
// -------------------------------------
