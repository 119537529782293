// *************************************
//
// Grid (flexbox)
//
// *************************************

.line {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  margin-right: -$base-whitespace-s;
  margin-left: -$base-whitespace-s;
}

.line--fill-space {
  align-items: stretch;
}

.unit {
  flex: 0 0 auto;
  padding-right: $base-whitespace-s;
  padding-left: $base-whitespace-s;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.size1of2 {
  flex-basis: 50%;
}

.size1of3 {
  flex-basis: 33.3333%;
}

.size2of3 {
  flex-basis: 66.6666%;
}

.size1of4 {
  flex-basis: 25%;
}

.size3of4 {
  flex-basis: 75%;
}

.size1of5 {
  flex-basis: 20%;
}

.size2of5 {
  flex-basis: 40%;
}

.size3of5 {
  flex-basis: 60%;
}

.size4of5 {
  flex-basis: 80%;
}

.size1of6 {
  flex-basis: 16.6666%;
}

.size5of6 {
  flex-basis: 83.3333%;
}
