// *************************************
//
// TextField
//
// *************************************
.text-field {
  margin-bottom: 28px;
  .form-error {
    margin-top: 5px;
    font-size: 13px;
  }
  input {
    background-color: transparent;
    border-style: solid;
    border-color: darken($color-light, 10%);
    border-width: 0px 0px 1px 0px;
  }
  input:focus {
    border-color: darken($color-light, 30%);
  }
  input:read-only {
    border: none;
  }
}

// -------------------------------------
// Submodules
// -------------------------------------

// -------------------------------------
// Context
// -------------------------------------

.has-error {
  margin-bottom: 7px;

  input,
  input:focus {
    border-color: $color-alert;
  }
}
