// *************************************
//
// Modal
//
// *************************************
.modal {
  position: absolute;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// -------------------------------------
// Submodules
// -------------------------------------

.modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #e5e5e5;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  &:focus {
    outline: none;
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: auto;
  background-color: #000;
  opacity: 0.5;
}

.modal-title {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 700;
}

.modal-button {
  @extend .button--medium;
  border: none;
  padding: 17px 57px;
}

.modal-button-primary {
  background-color: $pallet-vert-de-gris;
  color: $color-pure-white;
  font-size: 18px;
}

.modal-button-secondary {
  background-color: $pallet-sable;
  color: $pallet-noir;
  font-size: 18px;

  &:hover {
    background-color: $pallet-sable;
    color: $pallet-gris-2;
  }
}
