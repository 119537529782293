// *************************************
//
// NavBar
//
// *************************************

.nav-bar {
  font-family: $ff-serif;
  text-align: center;
}

// -------------------------------------
// Submodules
// -------------------------------------

.nav-bar-link {
  display: inline-block;
  padding: 8px 16px;
  color: $color-pure-white;
  margin-right: 4px;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background-color: $pallet-gris-3;
  }

  &.active,
  &.is-current {
    background-color: #f0f0f0;
    color: $color-contrast;
  }

  &.is-current {
    pointer-events: none;
  }
}
