// *************************************
//
// Welcome
//
// *************************************
.welcome {
  width: 500px;
  padding: 30px;
}

// -------------------------------------
// Submodules
// -------------------------------------
.welcome-message {
  margin-bottom: 50px;
  font-family: $ff-serif;
}
