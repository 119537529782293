// *************************************
//
// Variables
// Fonts, Variables
//
// *************************************

// -------------------------------------
// @font-face
// -------------------------------------

$ff-serif: "Gentium Plus", Georgia, serif;
$ff-trajan-family: "trajan-pro-3";
$ff-copy: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$ff-heading: "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
  Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$sans-family: "PT Sans", "Helvetica Neue", Arial, sans-serif;

// ----- Color pallet ----- //

$pallet-clair: #f9f9f9;
$pallet-sable: #f0f0f0;
$pallet-gris-1: #dddcdb;
$pallet-gris-2: #b3b1af;
$pallet-gris-3: #8a8a8a;
$pallet-vert-de-gris: #4e4e44;
$pallet-noir: #42423f;
$pallet-bleu-edition: #179bff;
$pallet-vert-thesaurus: #5b825a;
$pallet-vert-attestation: #d7ddd7;
$pallet-rouge: #d05353;
$pallet-orange: #e39620;

// ----- Colors ----- //

$color-copy: #3c3c3c;
$color-brand: #6e645a;
$color-contrast: $pallet-vert-de-gris;
$color-light: #c4cad0;
$color-brand-dim: #f0efee;
$color-positive: #93cf43;
$color-alert: $pallet-rouge;
$color-off-white: #f1f1f1;
$color-pure-white: #ffffff;

$input-color: #e2e2e2;

// ----- Base Content ----- //

$base-max-width: 62.5em; // ~1000px
$base-font-size: 16px;
$base-line-height: 1.5;
$base-whitespace: 24px;
$base-whitespace-xs: calc($base-whitespace / 3);
$base-whitespace-s: 0.5 * $base-whitespace;
$base-whitespace-l: 2 * $base-whitespace;
$base-whitespace-xl: 4 * $base-whitespace;

// ----- Breakpoint ----- //

$bp-tablet: 800px;

// ----- Z-index ----- //

$z-index-header: 1000;
$z-index-tooltip: 1010;
$z-index-modal: 1050;

// ----- Page bounds ----- //
$page-header-height: 100px;
