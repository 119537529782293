// *************************************
//
// Users
//
// *************************************
.users {
  color: $color-contrast;
  height: 100%;

  .page {
    margin-top: 30px;
  }
}

// -------------------------------------
// Submodules
// -------------------------------------

.user-name {
  font-family: $sans-family;
  color: $color-contrast;
  font-weight: 900;
  font-size: 26px;
  text-align: center;
}

.user-email-value {
  font-family: $ff-serif;
  color: $color-contrast;
  font-size: 18px;
}

// -------------------------------------
// Delete account
// -------------------------------------

.delete-user-button {
  font-size: 14px;
  color: #e53e3e;
  background-color: white;
  border: 1px solid #e53e3e;
  border-radius: 0;
  padding: 8px 18px;

  .my-account-page & {
    margin-top: 48px;
  }
}

.delete-user-modal {
  text-align: center;
  width: 542px;
  padding: 0 30px;
}

#delete-user-modal-title {
  padding-top: 25px;
}

.delete-user-modal-content {
  font-size: 18px;
  color: $pallet-rouge;
  padding: 20px;
  overflow-y: auto;
}

.delete-user-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin: 30px 0;
}

.delete-user-modal-button-confirm {
  background-color: $pallet-rouge;
  color: $color-pure-white;

  &:hover {
    background-color: fade-out($pallet-rouge, 0.2);
  }
}

.users-list-delete-button {
  display: block;
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;

  &.icon-delete {
    background-image: url("/images/icon-delete.svg");
  }
}

// -------------------------------------
// Create account
// -------------------------------------

.create-account-modal {
  text-align: left;
  width: 642px;
  padding: 0 30px;
}

#create-account-modal-title {
  padding-top: 15px;
  border-top: solid $pallet-noir 3px;
}

.create-account-modal-buttons {
  display: flex;
  justify-content: space-around;
  margin: 30px 85px;
}

.users-list-container {
  max-height: calc(100% - 220px);
}

.users-list-content {
  display: table;
  margin-left: auto;
  margin-right: auto;

  .create-account-button {
    font-family: $sans-family;
    padding: 11px 15px;
    margin-top: 30px;
    margin-bottom: 25px;
  }
}

.two-colums {
  position: relative;

  .text-field,
  .create-account-role {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: middle;

    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}

.create-account-role {
  h2 {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
  }

  &.isAdmin {
    margin-top: 20px;

    .isAdminSwitch,
    h2 {
      display: inline-block;
      vertical-align: middle;
    }
    .isAdminSwitch {
      margin-top: 5px;
      margin-left: 20px;
    }
  }
}

// -------------------------------------
// Users list
// -------------------------------------

.users-list {
  border-collapse: separate;

  thead th {
    border-bottom: 2px solid $color-contrast;
  }

  tbody {
    max-height: 400px;
  }

  th {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
  }

  td,
  th {
    width: 1px;
    max-width: 100%;
    white-space: nowrap;
    padding: 10px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-item-lastName {
    min-width: 160px;
    max-width: 160px;
  }
  .user-item-firstName {
    min-width: 110px;
    max-width: 110px;
  }
  .user-item-email {
    min-width: 250px;
    max-width: 250px;
  }
}

.users-item {
  font-size: 14px;
}

.users-pagination {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  top: 600px;
  left: 50%;
  transform: translateX(-50%);

  button {
    font-family: $ff-copy;
    font-size: 12px;
    &.isActive {
      background-color: $pallet-vert-de-gris;
      border: 0;
      color: $color-pure-white;
    }
    &:focus {
      outline: none;
    }
    &:first-child {
      background-color: transparent;
      margin-right: 10px;
    }
    &:last-child {
      background-color: transparent;
      margin-left: 10px;
    }
    &:hover {
      color: $pallet-gris-3;
    }
  }
}

.user-customer-not-found {
  font-size: 20px;
  text-align: center;
  color: $color-brand;
}
