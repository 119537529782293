// *************************************
//
// Visible password.
//
// *************************************
.show-password-button {
  width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;

  &.icon-eye-opened {
    background-image: url("/images/icon-open-eye.svg");
  }

  &.icon-eye-closed {
    background-image: url("/images/icon-closed-eye.svg");
  }
}

.visible-password {
  position: relative;

  .text-field {
    display: inline-block;
    width: 100%;
  }

  .show-password-button {
    display: inline-block;
    position: absolute;
    top: 30px;
    transform: translateX(-20px);
  }
}
