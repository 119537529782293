.login-page {
  height: 100%;
}

// -------------------------------------
// Header
// -------------------------------------
.connexion-header {
  @extend .header;
  align-items: flex-start;
}

.connexion-header-left {
  @extend .header-left;
}

.connexion-header-menu {
  font-family: $ff-serif;
  color: white;
  text-align: right;
  margin-left: auto;
  margin-right: 30px;
  position: relative;
  text-transform: uppercase;
  display: flex;

  .menu-item {
    padding: 12px 12px 12px 12px;
    font-size: 14px;
    font-weight: 300;
    text-decoration: none;
    color: $color-off-white;

    &.is-current {
      pointer-events: none;
      color: $color-contrast;
      background-color: $color-off-white;
    }

    &:not(:first-child) {
      margin-left: 12px;
    }
  }
}

.informations-container {
  flex: 1 0 auto;
  max-width: 580px;

  input {
    background-color: #f0f0f0;
  }

  input:-webkit-autofill{
    -webkit-box-shadow: 0 0 0 30px #f0f0f0 inset !important;
  }
}

.login-form-actions {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
