// *************************************
//
// Header
//
// *************************************

.header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: $color-contrast;
  padding: 0 10px;
}

// -------------------------------------
// Submodules
// -------------------------------------

.app-header {
  @extend .header;
  align-items: flex-start;

  &.suspens {
    padding: 10.5px 0 0 10px;
  }
}

.header-left {
  display: flex;
  height: 100%;
  align-items: center;

  .suspens & {
    min-width: 250px;
    max-width: 250px;
  }
}

.header-company {
  height: 100%;
  flex: 0 0 auto;
  display: inline-block;

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 0;
    min-height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
  }
}

.site-title-logo {
  width: 100px;
  display: inline-block;
  margin: 0 10px;
}

.site-title-baseline {
  display: inline-block;
  visibility: visible;
  width: 120px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #909090;
}

.header-app-links {
  margin-left: 20px;
  border-left: 1px solid white;
  display: flex;
  align-items: center;
}

.header-app-link {
  margin-left: 20px;
  padding: 5px 0;

  a {
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.vega a {
    height: 40px;
    width: 60px;
    font-size: 0;
  }
}

.header-nav {
  display: flex;
  justify-content: center;
  align-self: flex-end;
  margin-right: 20px;
  flex: 1 1 auto;
}

.header-user-menu {
  height: 100%;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  white-space: nowrap;
  font-family: $ff-serif;
  color: #b3b1af;
  font-size: 15px;
  margin: 0 15px;
  line-height: 1.5;
  position: relative;
  cursor: pointer;

  button {
    font-size: 14px;
    line-height: 1.15;
    font-family: $sans-family;
    font-weight: 700;
    text-transform: uppercase;
  }
}
